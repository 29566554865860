:root{
  --swiper-navigation-color : white;
  --swiper-navigation-size: 32px;
}

html{
  background-color: black;
    background-image: url('./assets/bg-gradient.svg') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height:100%;
}
body{   
  background-color: black;
    background-image: url('./assets/bg-gradient.svg') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    user-select: none;
    height:100%;
}

.absolute-fill{
  height:100vh;
  height:100dvh;
}

#loadBackground{
  display: none;
}

#loadingContainer img{
  display:inline;
}

#requestingCameraIcon{
  display:block !important;
}

.absolute-fill{
  background-color: black;
  background-image: url('./assets/bg-gradient.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

#loadingContainer{
  background-color: black;
  background-image: url('./assets/bg-gradient.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.App{
  /*touch-action: none;
  pointer-events: none;*/
}

canvas{
  touch-action: auto;
  pointer-events:all;
}

@font-face {
    font-family: "GeomGraphic-Light";
    src: url('./assets/fonts/GeomGraphicLight/font.woff2') format('woff2'), url('./assets/fonts/GeomGraphicLight/font.woff') format('woff');
  }
  @font-face {
    font-family: "GeomGraphic-SemiBold";
    src: url('./assets/fonts/GeomGraphicSemiBold/font.woff2') format('woff2'), url('./assets/fonts/GeomGraphicSemiBold/font.woff') format('woff');
  }
  
  .Typewriter__cursor{
    display: inline-block;
    width:10px;
    height:100%;
    background-color: #F1B700;
    color:transparent;
  }

  .tp-dfwv {
    z-index: 1000;
    display:none;
  }

  .swiper-slide{
    opacity:0;
    transition: all 300ms !important;
     display:flex;
    justify-content:center;
    align-items: end;
    
  }

  .swiper-slide-visible{
    opacity:0.3;
   
  }

  .swiper-slide-active{
    opacity:1;
    
  }



.swiper-button-prev {
    left: 25% !important;
    right: auto;
    transform: scale(0.6);
}
.swiper-button-next {
  right: 25% !important;
  left: auto;
  transform: scale(0.6);
}

  .swiper{
    
  }


  .touchable {
    touch-action: auto;
    pointer-events: auto;
  }